import store from "@/store/index";
import { nameTitleUser } from "@/core/config/DistribuidorNameUser"


let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
let link_v2 = "";

if(dominio_gestao == "localhost:8080"){
  link_v2 = tipo_conexao+"://localhost/acampa-gestao-pra/v2";
}else{
  link_v2 = "../v2";
}

let link_v3 = "../v3";
let menuCalculadora = {};

if(distribuidor == "atria" || distribuidor =="homologacao" || distribuidor == "localhost"){
  menuCalculadora = {
    heading: "Calculadora de Preços",
    route: "calculadora-precos",
    codRequired: [-1],
    // fontIcon: "fa-th-large",
    svgIcon: "./media/icons/duotune/shopping/calculator.svg",
  };
}

const DocMenuConfig = [
  {
    route: "/",
    pages: [
      {
          sectionTitle: "Cadastros",
          route: "/cadastros",
          codRequired: [-1, 2],
          // fontIcon: "fa-layer-group",
          svgIcon: "./media/icons/duotune/abstract/abs027.svg",
          sub: [
              {
                  heading: "Grupos",
                  route: link_v3 + "/cadastros/grupos/list",
                  codRequired: [-1, 2],
              },
              {
                  heading: "Concessionárias",
                  route: link_v3 + "/cadastros/concessionaria/list",
                  codRequired: [-1, 2],
              },
              {
                  heading: nameTitleUser[distribuidor] ?? "Consultores",
                  // route: link_v3 + "/cadastros/consultores/list",
                  route: link_v3 + "/cadastros/consultores/list",
                  codRequired: [-1, 2],
              },
              {
                  heading: "Mecânicos",
                  hiddenDistribuidor : ['stellantis'],
                  // route: link_v3 + "/cadastros/mecanicos/list",
                  route: link_v3 + "/cadastros/mecanicos/list",
                  codRequired: [-1, 2],
              },
              {
                  heading: "Serviços",
                  hiddenDistribuidor : ['stellantis'],
                  route: link_v3 + "/cadastros/servicos/list",
                  // route: link_v2 + "/servicoconcessionarialist.php",
                  codRequired: [-1, 2],
              },
              {
                  heading: "Usuários",
                  route: link_v3 + "/cadastros/usuario/list",
                  // hiddenDistribuidor : ['stellantis'],
                  // route: distribuidor == "stellantis" ? link_v3 + "/cadastros/usuario/list" : link_v2 + "/usuariolist.php",
                  codRequired: [-1, 2],
              }

          ],
      },
      {
          sectionTitle: "Vendas",
          route: "/subscriptions2",
          codRequired: [-1, 1, 2],
          hiddenDistribuidor : ['stellantis'],
          // fontIcon: "fa-chart-area",
          svgIcon: "./media/icons/duotune/graphs/gra003.svg",
        sub: [
          // {
          //   heading: "Lancamento Mensal",
          //   route: link_v2 + "/lancamentolist.php",
          //   codRequired: [-1, 1, 2],
          // },
          {
            heading: "Novo Lancamento Mensal",
            route: link_v3 + "/vendas/lancamento-mensal",
            codRequired: [-1, 1, 2],
          },
          {
            heading: "Relatório em Excel",
            route: link_v2 + "/relatorioslist.php",
            codRequired: [-1, 2],
          },
          // {
          //   heading: "Novo Relatório em Excel",
          //   route: link_v3 + "/vendas/relatorio-excel",
          //   codRequired: [-1, 2],
          // },
          // {
          //   heading: "Importação Workflow",
          //   route: link_v2 + "/lancamentoaddlote_workflow.php",
          //   codRequired: [-1, 2],
          // },
          {
            heading: "Importação Workflow",
            route: link_v3 + "/vendas/lancamento-mensal/workflow",
            codRequired: [-1, 2],
          },
          // {
          //   heading: "Integração DMS",
          //   route: link_v2 + "/consultamensalintegracaolist.php",
          //   codRequired: [-1, 2],
          // },
          {
            heading: "Integração DMS",
            route: link_v3 + "/vendas/integracao-dms",
            codRequired: [-1, 2],
          },
        ],
      },
      {
        heading: "Estoque",
        // fontIcon: "fa-truck",
        route: link_v3 + "/sugestaoPedidos",
        // route: link_v2 + "/controleestoquelist.php",
        svgIcon: "./media/icons/duotune/ecommerce/ecm006.svg",
        codRequired: [-1, 1, 2],
        hiddenDistribuidor : ['stellantis'],
      },
      {
        heading: "Cardápios",
        route: link_v2 + "/cardapioconcessionarialist.php",
        // fontIcon: "bi-front",
        hiddenDistribuidor : ['stellantis'],
        svgIcon: "./media/icons/duotune/general/gen054.svg",
        codRequired: [-1, 2],
       /*  sub: [
          {
            heading: "Acampa Administração",
            route: link_v2 + "/cardapioconcessionarialist.php",
            codRequired: [-1, 2],
          },
          {
            heading: "Acampa Cliente",
            route: link_v2 + "/oferta/",
            codRequired: [-1, 2],
          },
        ], */
      },
      {
        sectionTitle: "Oficina Digital",
        codRequired: [-1, 1, 2, 3],
        hiddenDistribuidor : ['stellantis'],
        route: "/oficina-digital",
        // fontIcon: "fa-desktop",
        svgIcon: "./media/icons/duotune/general/tools-duotone.svg",
        sub: [
          {
            heading: "Em andamento",
            route: link_v3 + "/oficina-digital/gerenciar",
            codRequired: [-1, 1, 2, 3],
          },
          {
            heading: "Relatório Completo",
            route: link_v3 + "/oficina-digital/finalizados",
            codRequired: [-1, 1, 2, 3],
          },
          {
            heading: "Itens Reprovados",
            route: link_v3 + "/oficina-digital/reprovados",
            codRequired: [-1, 1, 2, 3],
          },
          {
            heading: "Histórico",
            route: link_v3 + "/oficina-digital/historico",
            codRequired: [-1, 1, 2, 3],
          },
        ],
      },
      {
        heading: "Histórico de Checklist",
        route: link_v3 + `${distribuidor == 'stellantis' ? "/historico-checklist-stellantis" : "/historico-checklist"}`,
        codRequired: [-1, 1, 2, 3,6],
        // fontIcon: "fa-calendar",
        svgIcon: "./media/icons/duotune/shopping/clipboard-list-dt.svg",
      },
      {
        heading: "Lista de OS",
        route: link_v3 + "/ordemservicogerenciar",
        // route: link_v2 + "/ordemservicogerenciar.php",
        codRequired: [-1,  2, 1],
        hiddenDistribuidor : ['stellantis'],
        // fontIcon: "fa-list",
        svgIcon: "./media/icons/duotune/text/txt009.svg",
      },
      {
        heading: "Gerenciar Pesquisas",
        route: link_v2 + "/pesquisadashboardlist.php",
        codRequired: [-1, 1, 2],
        hiddenDistribuidor : ['stellantis'],
        // fontIcon: "fa-th-large",
        svgIcon: "./media/icons/duotune/shopping/star-half-alt.svg",
      },
      menuCalculadora,
      {
        sectionTitle: "Agenda Virtual",
        codRequired: [-1, 1, 2, 4, 5],
        route: "/agenda-virtual",
        hiddenDistribuidor : ['stellantis'],
        // fontIcon: "fa-desktop",
        svgIcon: "./media/icons/duotune/shopping/calendar-alt-dt.svg",
        sub: [
          {
            heading: "Quadro de Agendamentos",
            route: link_v3 + "/agenda-virtual/exibir-consultor",
            codRequired: [-1, 1, 2, 4, 5],
          },
          {
            heading: "Quadro de Recepção",
            route: link_v3 + "/agenda-virtual/recepcao",
            codRequired: [-1, 1, 2, 4, 5],
          },
          {
            heading: "Quadro de Programação",
            route: link_v3 + "/agenda-virtual/agendar",
            codRequired: [-1, 1, 2, 4],
          },
          {
            heading: "Quadro de Serviços",
            route: link_v3 + "/agenda-virtual/exibir",
            codRequired: [-1, 1, 2, 4, 5],
          }
        ],
      },
      {
        sectionTitle: "Ferramentas do Desenvolvedor",
        codRequired: [-1],
        hiddenDistribuidor : ['stellantis'],
        route: "/ferramentas-desenvolvedor",
        // fontIcon: "fa-desktop",
        svgIcon: "./media/icons/duotune/coding/cod003.svg",
        sub: [
          {
            heading: "Gerenciar checklist",
            route: link_v3 + "/ferramentas-desenvolvedor/gerenciar-checklist",
            codRequired: [-1],
          },
          {
            heading: "Configuração do app",
            route: link_v3 + "/ferramentas-desenvolvedor/configuracao-app",
            codRequired: [-1],
          },
        ],
      },
    ],
  },
];


export default DocMenuConfig;
