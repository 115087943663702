import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_3 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_KTAside = _resolveComponent("KTAside")!
  const _component_KTHeader = _resolveComponent("KTHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createVNode(_component_KTAside, {
        lightLogo: _ctx.themeLightLogo,
        darkLogo: _ctx.themeDarkLogo
      }, null, 8, ["lightLogo", "darkLogo"]), [
        [_vShow, _ctx.asideEnabled && !_ctx.layoutQuadro]
      ]),
      _createElementVNode("div", {
        id: "kt_wrapper",
        class: _normalizeClass({
      'd-flex' : true,
      'flex-column' : true,
      'flex-row-fluid' : true,
      'wrapper': !_ctx.layoutQuadro
    })
      }, [
        _createVNode(_component_KTHeader, {
          user: !_ctx.layoutQuadro
        }, null, 8, ["user"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              id: "kt_content_container",
              class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              'container-xxl': !_ctx.contentWidthFluid,
              'p-0' : _ctx.layoutQuadro
            })
            }, [
              _createVNode(_component_router_view, { id: "layout_content" })
            ], 2)
          ])
        ])
      ], 2)
    ]),
    _createVNode(_component_KTScrollTop)
  ], 64))
}